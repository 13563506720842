import { ScheduleItem } from "../../../global-data/types/schedule-item";

import './test-list.scss'

interface ScheduleProps {
    data: ScheduleItem
  }
  
  export function ShowSchedule(props: ScheduleProps) {
    return (
      <div className="row">
        <div className="col-2 fs-5 fw-bold">
          {props.data.title}
        </div>
        <div className="col-4 fs-5">
          {props.data.subtitle}
        </div>
        <div className="col-6 fs-5">
          {props.data.text}
        </div>
      </div>
    )
  }
  