import { ScheduleItem } from '../types/schedule-item'

export class GlobalDataService {
  public static schedule: ScheduleItem[] = [
    {
      id: '1',
      title: 'Mondays',
      subtitle: '6PM PST/9PM EST',
      text: 'Alternating styles each week between organic house and psychedelic.',
    },
    {
      id: '2',
      title: 'Weekends',
      subtitle: 'Watch Announcements',
      text: 'Dark beats. Industrial. EBM. Dark Disco.',
    },
  ]
}
