import { ScheduleItem } from "../../../global-data/types/schedule-item";
import { ShowSchedule } from "./test-list";


  interface ScheduleProps {
    list: ScheduleItem[]
  }
  
  export function Schedule(props: ScheduleProps) {
    return (
      <>
        <div className="container">
          <div className="row text-center"> 
              <div className="col">
                <h1 className="display-2">
                <b>Schedule</b>
                </h1>
              </div>       
          </div>
          {props.list.map(x => <ShowSchedule key={x.id} data={x}/>)}
        </div>
      </>
    )
  }